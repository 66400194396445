import { useNavigate } from 'react-router-dom';
import { USER_AUTH_TOKEN, USER_ROLE } from 'src/shared/domain/auth/localStorageKeys';

export const useRemoveToken = () => {
  const router = useNavigate();

  return () => {
    console.log('what')
    localStorage.removeItem(USER_AUTH_TOKEN);
    localStorage.removeItem(USER_ROLE);
    router('/login');
  };
};
