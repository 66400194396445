import * as React from 'react';
import { useQuery } from 'react-query';
import { USER_AUTH_TOKEN, USER_ROLE } from 'src/core/domain/auth/localStorageKeys';
import { globalUserToken } from 'src/core/query/clientConfig';
import { isAuthError } from 'src/core/query/errorutils';
import { sharedAuthQueryKeygen } from 'src/core/query/sharedKeygen';
import { useRemoveToken } from './useRemoveToken';

export const useIsLogin = () => {
  const logOut = useRemoveToken();
  const innerToken = localStorage.getItem(USER_AUTH_TOKEN);
  const innerRole = localStorage.getItem(USER_ROLE);
  const { data, error, isLoading } = useQuery({
    ...sharedAuthQueryKeygen('/api/v1/auth/user/token-validation', {
      params: {
        header: {
          Authorization: `Bearer ${innerToken}`,
        },
      },
    }),
    enabled: !!innerToken && !!innerRole,
    retry: false,
  });

  React.useEffect(() => {
    if (!innerToken && !innerRole) logOut();
    if (error && isAuthError(error.response)) {
      localStorage.removeItem(USER_AUTH_TOKEN);
      localStorage.removeItem(USER_ROLE);
      logOut();
    } else if (innerRole && innerToken && data) {
      localStorage.setItem(USER_AUTH_TOKEN, innerToken);
      localStorage.setItem(USER_ROLE, innerRole);
      globalUserToken.value = innerToken;
    }
  }, [data, error, innerRole, innerToken, logOut]);

  return isLoading;
};
